<!--
 * @Author: DY
 * @Date: 2021-02-23 10:45:43
 * @LastEditTime: 2021-02-23 15:14:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\sso\yunzhijia\login.vue
-->
<template>
    <div class="singleSignOn">
        <div class="content">
            <h3>{{ newText }}</h3>
            <button @click="openLogin">
                前往登录页
            </button>
        </div>
    </div>
</template>

<script>
import cookie from '@/common/NIM/utils/cookie';
import { reAddRouters } from '@/utils';

export default {
    // 单点登录中转页
    name: 'single-sign-on',
    components: {},
    props: {},
    data() {
        return {
            ticket: '',
            newText: '登录中。。。',
        };
    },
    computed: {},
    watch: {},
    created() {
        this.ticket = this.$queryString(this.$route.fullPath, 'ticket');
        if (this.ticket) {
            // 获取token
            this.getToken();
        } else {
            this.newText = '参数错误，ticket 缺失!';
        }
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 获取token
        async getToken() {
            await this.$axios
                .post('/interfaceApi/kingdee/SSO/jt/yunzhijia/login', {
                    ticket: this.ticket,
                })
                .then(res => {
                    sessionStorage.setItem('token', res.AccessToken);
                    sessionStorage.setItem('refreshToken', res.RefreshToken);
                    sessionStorage.setItem('clientID', this.guid);
                    const name = this.$takeTokenParameters('Name');
                    this.getNIMAccount(name);
                })
                .catch(error => {
                    this.newText = error.ErrorCode.Message;
                    // this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取云信账号
        getNIMAccount(name) {
            this.$axios
                .get('/interfaceApi/message/MessageNetease/GetRelInfo?userName=' + name)
                .then(res => {
                    cookie.setCookie('uid', res.neteaseid);
                    cookie.setCookie('sdktoken', res.neteasetoken);
                    this.$store.dispatch('connect', this.$store);
                    console.log(333);
                    cookie.setCookie('isNIM', 'true');
                    if (this.$takeTokenParameters('DeptType') === '1' || this.$takeTokenParameters('DeptType') === '2') {
                        localStorage.setItem('menuCode', '');
                        this.$router.push({ path: '/EBCHome' });
                    } else {
                        localStorage.setItem('menuCode', 'shebc');
                        console.log(444);
                        // 重新添加路由信息
                        reAddRouters();
                        this.$router.push({ path: '/' });
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                    cookie.delCookie('uid');
                    cookie.delCookie('sdktoken');
                    cookie.setCookie('isNIM', 'false');
                    if (this.$takeTokenParameters('DeptType') === '1' || this.$takeTokenParameters('DeptType') === '2') {
                        localStorage.setItem('menuCode', '');
                        this.$router.push({ path: '/EBCHome' });
                    } else {
                        localStorage.setItem('menuCode', 'shebc');
                        // 重新添加路由信息
                        reAddRouters();
                        this.$router.push({ path: '/' });
                    }

                });
        },
        // 打开登录页
        openLogin() {
            this.$router.push({ path: '/' });
        },
    },
};
</script>
<style lang="stylus" scoped>
.singleSignOn
    width 100%
    height 100%
    display flex
    .content
        width 14rem
        height 9rem
        margin auto
        text-align center
        background url(../images/login/bg.png) no-repeat;
        position relative
        h3
            color #979EA7
            font-size 0.4rem
            text-align center
            width 100%
            position absolute
            bottom 2.2rem
            left 50%
            transform translateX(-50%)
        button
            width 3.6rem
            height 0.76rem
            font-size 0.28rem
            line-height 0.76rem
            color #fff
            border none
            border-radius 2px
            background linear-gradient(270deg, #3A96FE 0%, #40B6FF 100%)
            position absolute
            bottom 1rem
            left 50%
            transform translateX(-50%)
            cursor pointer
</style>
